import { Component, Input, NgZone, OnInit } from '@angular/core';

@Component({
    selector: 'temperature-vis',
    templateUrl: './temperature-vis.component.html',
    styleUrls: [
        './temperature-vis.component.scss',
        '../data-vis.scss'
    ]
})
export class TemperatureVisComponent implements OnInit {

    private _temperature: number = 0;

    @Input() set temperature(value: number) {
        this.zone.run(() =>
            this._temperature = value
        );
    }

    constructor(
        private zone: NgZone,
    ) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    get temperature(): number {
        return this._temperature;
    }
}