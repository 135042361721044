import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexPageComponent } from './index-page/index-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
  //{
  //  path: 'crisis-center',
  //  loadChildren: () => import('./crisis-center/crisis-center.module').then(m => m.CrisisCenterModule),
  //  data: { preload: true }
  //},
  //{ path: '',   redirectTo: '/indexPage', pathMatch: 'full' },
  { path: '', component: IndexPageComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
