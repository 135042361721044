import { Component, Input, NgZone, OnInit } from '@angular/core';

@Component({
    selector: 'humidity-vis',
    templateUrl: './humidity-vis.component.html',
    styleUrls: [
        './humidity-vis.component.scss',
        '../data-vis.scss'
    ]
})
export class HumidityVisComponent implements OnInit {

    private _humidity: number = 0;

    @Input() set humidity(value: number) {
        this.zone.run(() =>
            this._humidity = value
        );
    }


    constructor(
        private zone: NgZone,
    ) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    get humidity(): number {
        return this._humidity;
    }

}