import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SensorListService {
  private sensorUrlPath: string = 'https://data.bjoernglawe.de/sensor/';

  public sensorList: string[] = [];
  public currentSensor: string = '';

  public sensorListSubject: Subject<string[]>;
  public currentSensorSubject: Subject<string>;

  constructor(
    private http: HttpClient,
  ) {
    this.sensorListSubject = new Subject<string[]>();
    this.sensorListSubject.next();
    this.currentSensorSubject = new Subject<string>();
    this.currentSensorSubject.next();
  }

  public getSensorListObserver(): Observable<string[]> {
    return this.sensorListSubject.asObservable();
  }

  public getCurrentSensorObserver(): Observable<string> {
    return this.currentSensorSubject.asObservable();
  }

  public async getSensorList() {
    this.http
      .get(this.sensorUrlPath, { responseType: 'text' })
      .subscribe((data: any) => {
        this.sensorList = JSON.parse(data);
        this.sensorListSubject.next(this.sensorList);
        if (this.currentSensor == '') {
          this.setCurrentSensor(this.sensorList[0]);
        }
      });
  }

  public async setCurrentSensor(sensor: string) {
    this.sensorList.forEach(ele => {
      if (ele === sensor) {
        this.currentSensor = sensor;
        this.currentSensorSubject.next(sensor);
      }
    });
  }
}
