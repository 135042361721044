import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DSVRowString } from 'd3';
import * as d3 from 'd3';

@Injectable({
    providedIn: 'root',
})
export class SensorHistoryDataService {
    private sensorUrlPath: string = 'https://data.bjoernglawe.de/sensor/'; // :year/:month/:day

    public sensorData: DSVRowString[] = [];
    public sensorDataSubject: Subject<DSVRowString[]>;

    private requestCounter = 0;  // counts data requests
    private responseCounter = 0; // counts data responses

    constructor(
        private http: HttpClient,
    ) {
        this.sensorDataSubject = new Subject<any>();
        this.sensorDataSubject.next([]);
    }

    public getSensorDataObserver(): Observable<any> {
        return this.sensorDataSubject.asObservable();
    }

    public async getSensorData(sensorName: string, startDate: Date = new Date(), endDate?: Date) {
        this.requestCounter = 0;
        this.responseCounter = 0;
        this.sensorData = [];

        if (!endDate) { endDate = new Date(startDate); }

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            this.requestCounter++;
            //this.getSensorDataRequest(sensorName, d);

            let todayPath = this.sensorUrlPath + sensorName + '/' + d.getFullYear()
                + '/' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1))
                + '/' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());

            let reqResult = await this.http
                .get(todayPath, { responseType: 'text' })
                .toPromise()
                .then((data: any) => {
                    this.responseCounter++;
                    if (!data) {
                        return [];
                    }
                    else {
                        let procData = d3.csvParse(data);
                        return procData;
                    }
                }, (error: Error) => {
                    this.responseCounter++;
                    console.error(error);
                    return [];
                });
            this.sensorData = this.sensorData.concat(reqResult);
        }
        this.sensorDataSubject.next(this.sensorData);
        /* } else {
            console.log('single date');
            this.requestCounter++;
            this.getSensorDataRequest(sensorName, startDate);
        } */
    }

    /* public async getSensorDataRequest(sensorName: string, date: Date) {

        let todayPath = this.sensorUrlPath + sensorName + '/' + date.getFullYear()
            + '/' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1))
            + '/' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());

        this.http
            .get(todayPath, { responseType: 'text' })
            .subscribe((data: any) => {
                this.responseCounter++;
                if (!data) {
                    this.sensorData = this.sensorData.concat([]);
                }
                else {
                    let procData = d3.csvParse(data);
                    this.sensorData = this.sensorData.concat(procData);
                }
                if (this.requestCounter <= this.responseCounter) {
                    this.sensorDataSubject.next(this.sensorData);
                }
            }, (error: Error) => {
                this.responseCounter++;
                console.error(error);
                this.sensorData = this.sensorData.concat([]);
                if (this.requestCounter >= this.responseCounter) {
                    this.sensorDataSubject.next(this.sensorData);
                }
            });
    } */

}