import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SensorData } from '../models/sensor-data.model';

@Injectable({
    providedIn: 'root',
})
export class LastSensorDataService {
    private sensorUrlPath: string = 'https://data.bjoernglawe.de/sensor/'

    public sensorData: SensorData = new SensorData();
    public sensorDataSubject: Subject<SensorData>;


    constructor(
        private http: HttpClient,
    ) {
        this.sensorDataSubject = new Subject<SensorData>();
        this.sensorDataSubject.next();
    }

    public getSensorDataObserver(): Observable<SensorData> {
        return this.sensorDataSubject.asObservable();
    }

    public async getSensorData(sensorName: string) {
        let dataUrlPath = this.sensorUrlPath + sensorName + '/last';

        this.http
            .get(dataUrlPath)
            .subscribe((data: SensorData) => {
                this.sensorDataSubject.next(data);
            }, (error : Error) => {
                console.error(error);
                this.sensorDataSubject.next(new SensorData());
            });
    }

}