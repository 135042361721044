import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HumidityVisComponent } from './components/data-vis/humidity-vis/humidity-vis.component';
import { PressureVisComponent } from './components/data-vis/pressure-vis/pressure-vis.component';
import { TemperatureDiagramVisComponent } from './components/data-vis/temperature-diagram-vis/temperature-diagram-vis.component';
import { TemperatureVisComponent } from './components/data-vis/temperature-vis/temperature-vis.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { IndexPageComponent } from './index-page/index-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { HumidityDiagramVisComponent } from './components/data-vis/humidity-diagram-vis/humidity-diagram-vis.component';
import { PressureDiagramVisComponent } from './components/data-vis/pressure-diagram-vis copy/pressure-diagram-vis.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    IndexPageComponent,
    NavigationComponent,
    FooterComponent,
    TemperatureVisComponent,
    HumidityVisComponent,
    PressureVisComponent,
    TemperatureDiagramVisComponent,
    HumidityDiagramVisComponent,
    PressureDiagramVisComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
