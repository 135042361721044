import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3'
import { bisect, DSVRowString } from 'd3';

@Component({
    selector: 'pressure-diagram-vis',
    templateUrl: './pressure-diagram-vis.component.html',
    styleUrls: [
        './pressure-diagram-vis.component.scss',
        '../data-vis.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class PressureDiagramVisComponent implements OnInit {
    @Input() sensorData: DSVRowString[] = [];
    @Output() onChangeRangeDays: EventEmitter<number> = new EventEmitter();

    private width = 400;
    private height = 300;
    private margin = 20;

    public svg: any;
    public svgInner: any;
    public yScale: any;
    public xScale: any;
    public xAxis: any;
    public yAxis: any;
    public lineGroup: any;

    public tooltip: any;
    public hoverLine: any;
    public hoverCircle: any;

    constructor(
        public chartElem: ElementRef,
    ) {
    }

    ngOnChanges(changes: any): void {
        if (changes.hasOwnProperty('sensorData') && this.sensorData) {
            this.initializeChart();
            this.drawChart();

            window.addEventListener('resize', () => this.drawChart());
        }
    }

    ngOnInit() {
        //this.createChart(objs);
    }

    public setRangeDays(days: number) {
        this.onChangeRangeDays.emit(days);
    }

    private initializeChart(): void {

        // svg init
        d3.select(".linechartPres")
            .html("");
        this.svg = d3
            .select(this.chartElem.nativeElement)
            .select('.linechartPres')
            .append('svg')
            .attr('height', this.height);
        this.svgInner = this.svg
            .append('g')
            .style('transform', 'translate(' + this.margin + 'px, ' + this.margin + 'px)');

        // Tooltip Hover
        this.tooltip = d3.select(".linechartPres")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltipTextBox");
        this.hoverLine = this.svgInner
            .append("line")
            .style("opacity", 0)
            .attr("stroke", "var(--dataVisColor4)")
            .attr("stroke-dasharray", 8)
            .attr("x1", 10).attr("x2", 10)
            .attr("y1", 0).attr("y2", this.height - 2 * this.margin);
        this.hoverCircle = this.svgInner
            .append('circle')
            .style("opacity", 0)
            .style("z-index", 3)
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('r', 4)
            .attr('fill', 'var(--dataVisColor4)');

        // Y-ACHSE
        // Skalierung
        this.yScale = d3
            .scaleLinear()
            .domain([
                (d3.max(this.sensorData, (d:any) => parseFloat(d.pressure) / 10 as any) + 20),
                (d3.min(this.sensorData, (d:any) => parseFloat(d.pressure) / 10 as any) - 20)])
            .nice()
            .range([0, this.height - 2 * this.margin]);

        // Achse 	
        this.yAxis = this.svgInner
            .append('g')
            .attr('id', 'y-axis')
            .style('transform', 'translate(' + this.margin + 'px,  0)')
            .style('color', 'var(--generalFont)');

        // X-ACHSE
        // Skalierung
        this.xScale = d3.scaleTime()
            .domain(d3.extent(this.sensorData, d => new Date(d.unixTime as any * 1)) as [Date, Date]);
        // Achse
        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .style('transform', 'translate(0, ' + (this.height - 2 * this.margin) + 'px)')
            .style('color', 'var(--generalFont)');

        this.lineGroup = this.svgInner
            .append('g')
            .append('path')
            .attr('id', 'line')
            .style('fill', 'none')
            .style('stroke', 'var(--dataVisColor3)')
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .style('stroke-width', '1.5px');

    }

    private drawChart(): void {
        this.width = this.chartElem.nativeElement.getBoundingClientRect().width;
        this.svg.attr('width', this.width);

        // responsive x-Achse
        this.xScale.range([this.margin, this.width - 4 * this.margin]);

        const xAxis = d3
            .axisBottom(this.xScale)
            .ticks(this.width / 80)
            .tickFormat((d: any, i: any) => this.multiFormat(d));
        //.tickFormat(d3.timeFormat('%H:%M') as any); //10-05-2021 8:23:13

        this.xAxis.call(xAxis);

        // y-Achse
        const yAxis = d3
            .axisLeft(this.yScale);

        this.yAxis
            .call(yAxis)
            .call((g: any) => g.select(".domain").remove());

        // Linie
        const line = d3.line()
            .defined(d => !isNaN(d[1]))
            .x(d => d[0])
            .y(d => d[1])
            .curve(d3.curveMonotoneX);

        const points: [number, number][] = this.sensorData.map(d => [
            this.xScale(new Date(d.unixTime as any * 1)),
            this.yScale(parseFloat(d.pressure as any)/ 10),
        ]);

        this.lineGroup.attr('d', line(points));

        // single points at the line
        /* this.svgInner
            .selectAll('dot')
            .data(this.sensorData)
            .enter()
            .append('circle')
            .attr('cx', (d: any) => this.xScale(new Date(d.unixTime * 1)))
            .attr('cy', (d: any) => this.yScale(d.pressure))
            .attr('r', 2)
            .attr('fill', 'var(--dataVisColor4)'); */
        /* .on('mouseover', (d: any) => {
            this.tooltip.transition()
                .duration(200)
                .style('opacity', .9);
            this.tooltip.html('a tooltip <br/>' + d.unixTime + '<br/>' + d.pressure)
                .style('left', (d3.pointer(d)[0]) + 'px')
                .style('top', (d3.pointer(d)[1] - 28) + 'px');
        })
        .on('mouseout', (d: any) => {
            this.tooltip.transition()
                .duration(500)
                .style('opacity', 0);
        }); */


        this.svg
            .on("mousemove", (d: any) => {
                var mouse_x = d3.pointer(d)[0] - 10; // maybe cause margin, the hover is translated
                var mouse_y = d3.pointer(d)[1];

                var bisectDate = d3.bisector((d: any) => d.unixTime).left;
                var mouseDate = this.xScale.invert(mouse_x);
                var i = bisectDate(this.sensorData, mouseDate);

                var calcDate: any = this.sensorData[(i > 0 ? i - 1 : 0)];

                var eleXPos = (this.xScale(new Date(calcDate.unixTime * 1)));
                var eleYPos = (this.yScale(calcDate.pressure / 10));

                this.tooltip.transition()
                    .style('opacity', .9)
                    .style('display', "block");
                this.tooltip
                    .html(`${new Date(calcDate.unixTime * 1).toLocaleString()}<br/> ${calcDate.pressure / 10} hPa`)
                    .style('left', (mouse_x - 20) + 'px')
                    .style('top', (eleYPos + 100) + 'px');

                this.hoverLine
                    .attr("x1", eleXPos).attr("x2", eleXPos)
                    .style('opacity', .9);
                this.hoverCircle
                    .attr("cx", eleXPos).attr("cy", eleYPos)
                    .style('opacity', .9);

            })
            .on("mouseout", (d: any) => {
                this.tooltip.transition()
                    .style('opacity', 0)
                    .style('display', "none");
                this.hoverLine
                    .style('opacity', 0);
                this.hoverCircle
                    .style('opacity', 0);
            });
    }

    // formatter
    public germanFormatters = d3.timeFormatDefaultLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "currency": ["€", ""],
        "dateTime": "%a %b %e %X %Y",
        "date": "%d.%m.%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        "months": ["Jannuar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
    } as any);

    public formatMillisecond = this.germanFormatters.format(".%L");
    public formatSecond = this.germanFormatters.format(":%S");
    public formatMinute = this.germanFormatters.format("%H:%M");
    public formatHour = this.germanFormatters.format("%H:00");
    public formatDay = this.germanFormatters.format("%a (%d.%m.)");
    public formatWeek = this.germanFormatters.format("%d. %b");
    public formatMonth = this.germanFormatters.format("%B");
    public formatYear = this.germanFormatters.format("%Y");

    public multiFormat = (date: Date) => {
        return (d3.timeSecond(date) < date ? this.formatMillisecond
            : d3.timeMinute(date) < date ? this.formatSecond
                : d3.timeHour(date) < date ? this.formatMinute
                    : d3.timeDay(date) < date ? this.formatHour
                        : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? this.formatDay : this.formatWeek)
                            : d3.timeYear(date) < date ? this.formatMonth
                                : this.formatYear)(date);
    }
}