<nav class="navBar">
    <ul class="navBarLinks">
        <li>
            <h2>
                <img src="/assets/icon.png" alt="Dashboard-Icon">Dashboard
            </h2>
        </li>
        <li><a routerLink="/" routerLinkActive="active">Start</a></li>
        <li class="elementRight" (click)="toggleNightmode()">
            <a>
                <ion-icon name="moon-outline"></ion-icon>
            </a>
        </li>
    </ul>
</nav>