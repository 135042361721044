export class SensorData {
    constructor(
        public time?: string,
        public unixTime?: number,
        public temperature?: number,
        public humidity?: number,
        public pressure?: number,
        public waterLevel?: number,
    ) { }
}

/* export class SensorData {
    private _time: string;
    private _unixTime: number;
    private _temperature: number;
    private _humidity: number;
    private _pressure: number;
    private _waterLevel: number;

    constructor(
        time?: string,
        unixTime?: number,
        temperature?: number,
        humidity?: number,
        pressure?: number,
        waterLevel?: number,
    ) {
        this.time = time;
        this.unixTime = unixTime;
        this.temperature = temperature;
        this.humidity = humidity;
        this.pressure = pressure;
        this.waterLevel = waterLevel;
    }

    // GETTER & SETTER
    public get time(): string {
        return this._time;
    }
    public set time(value: string) {
        this._time = value;
    }

    public get unixTime(): number {
        return this._unixTime;
    }
    public set unixTime(value: number) {
        this._unixTime = value;
    }

    public get temperature(): number {
        return this._temperature;
    }
    public set temperature(value: number) {
        this._temperature = value;
    }

    public get humidity(): number {
        return this._humidity;
    }
    public set humidity(value: number) {
        this._humidity = value;
    }

    public get pressure(): number {
        return this._pressure;
    }
    public set pressure(value: number) {
        this._pressure = value;
    }

    public get waterLevel(): number {
        return this._waterLevel;
    }
    public set waterLevel(value: number) {
        this._waterLevel = value;
    }
} */