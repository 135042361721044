import { Component, OnInit } from '@angular/core';
import { Subject, TimeInterval } from 'rxjs';
import { LastSensorDataService } from '../services/last-sensor-data.service';
import { takeUntil } from 'rxjs/operators';
import { SensorData } from '../models/sensor-data.model';
import { DSVRowString } from 'd3';
import { SensorHistoryDataService } from '../services/sensor-history-data.service';
import { SensorListService } from '../services/sensor-list.service';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.scss']
})
export class IndexPageComponent implements OnInit {
  private destroyer = new Subject();

  public currentSensorName: string = '';
  public sensorList: string[] = [];

  public sensorData: SensorData = new SensorData();
  public historyDayRange: number = 1;
  public sensorHistoryData: DSVRowString[] = [];

  public sensorDataInterval: any = null;

  constructor(
    private lastSensorDataService: LastSensorDataService,
    private sensorHistoryDataService: SensorHistoryDataService,
    private sensorListService: SensorListService,
  ) {
    this.subscribeCurrentSensor();
    this.subscribeSensorList();
    this.sensorListService.getSensorList();

    this.sensorDataUpdate();
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
    clearInterval(this.sensorDataInterval);
  }

  ngOnInit() {
  }

  // Subscriptions

  public subscribeCurrentSensor() {
    this.sensorListService.getCurrentSensorObserver()
      .pipe(takeUntil(this.destroyer))
      .subscribe((sensorName: string) => {
        this.currentSensorName = sensorName;
        // get Data from Sensor
        this.subscribeLastSensorData();
        this.subscribeHistorySensorData();
      });
  }

  public subscribeSensorList() {
    this.sensorListService.getSensorListObserver()
      .pipe(takeUntil(this.destroyer))
      .subscribe((sensorList: string[]) => {
        this.sensorList = sensorList;
      });
  }

  public subscribeLastSensorData() {
    this.lastSensorDataService.getSensorDataObserver()
      .pipe(takeUntil(this.destroyer))
      .subscribe((data: SensorData) => {
        this.sensorData = data;
      });
    this.lastSensorDataService.getSensorData(this.currentSensorName);
  }

  public subscribeHistorySensorData() {
    this.sensorHistoryDataService.getSensorDataObserver()
      .pipe(takeUntil(this.destroyer))
      .subscribe((data: any) => {
        this.sensorHistoryData = data;
      });
    this.getSensorHistoryData();
  }

  // Functions

  public selectSensor(event: any) {
    this.sensorListService.setCurrentSensor(event.target.value);
  }

  public sensorDataUpdate() {
    this.sensorDataInterval = setInterval(() => {
      this.lastSensorDataService.getSensorData(this.currentSensorName);
      this.getSensorHistoryData();
    }, (60000 * 15))
  }

  public onChangeRangeDays(days: number) {
    this.historyDayRange = days;
    this.getSensorHistoryData();
  }

  public getSensorHistoryData() {
    let today = new Date();
    let otherDate = new Date();
    otherDate.setDate(otherDate.getDate() - this.historyDayRange);
    this.sensorHistoryDataService.getSensorData(this.currentSensorName, otherDate, today);
  }
}