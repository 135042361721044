import { Component, Input, NgZone, OnInit } from '@angular/core';

@Component({
    selector: 'pressure-vis',
    templateUrl: './pressure-vis.component.html',
    styleUrls: [
        './pressure-vis.component.scss',
        '../data-vis.scss'
    ]
})
export class PressureVisComponent implements OnInit {

    private _pressure: number = 0;

    @Input() set pressure(value: number) {
        this.zone.run(() =>
            this._pressure = value
        );
    }

    constructor(
        private zone: NgZone,
    ) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    get pressure(): number {
        return this._pressure;
    }

}