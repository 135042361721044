<div class="selectorWrapper">
    <select class="selectorField" name="sensors" id="sensor-select" (change)="selectSensor($event);">
        <ng-container *ngFor="let sensorEle of sensorList">
            <option value="{{sensorEle}}" [selected]="sensorEle == currentSensorName">{{ sensorEle }}</option>
        </ng-container>
    </select>
</div>

<p class="lastUpdateDate">
    <svg xmlns="http://www.w3.org/2000/svg" class="svgCircles">
        <circle class="outerCircle" cx="10" cy="10" r="10" fill="red" />
        <circle class="innerCircle" cx="10" cy="10" r="5" fill="green" />
    </svg> {{sensorData.time}}
</p>
<div class="gridContainer">
    <temperature-vis *ngIf="sensorData.temperature" [temperature]="sensorData.temperature"></temperature-vis>
    <humidity-vis *ngIf="sensorData.humidity" [humidity]="sensorData.humidity"></humidity-vis>
    <pressure-vis *ngIf="sensorData.pressure" [pressure]="sensorData.pressure"></pressure-vis>

    <temperature-diagram-vis *ngIf="sensorHistoryData[0]?.temperature" class="maxWidthColumn" [sensorData]="sensorHistoryData" (onChangeRangeDays)="onChangeRangeDays($event)"></temperature-diagram-vis>
    <humidity-diagram-vis *ngIf="sensorHistoryData[0]?.humidity" class="maxWidthColumn" [sensorData]="sensorHistoryData" (onChangeRangeDays)="onChangeRangeDays($event)"></humidity-diagram-vis>
    <pressure-diagram-vis *ngIf="sensorHistoryData[0]?.pressure" class="maxWidthColumn" [sensorData]="sensorHistoryData" (onChangeRangeDays)="onChangeRangeDays($event)"></pressure-diagram-vis>
</div>